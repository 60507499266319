import { InputProps, useInput } from '../useInput';

export const useForm = () => {
	const form: Record<string, any> = {};
	const errors: { name: string, error: boolean }[] = [];

	const addInput = (name: string, controlled: boolean, initial: string | number, options?: InputProps | undefined) => {
		if (form[name]) {
			throw 'Input with this name already exists';
		}

		form[name] = useInput(controlled, initial, options);
		return form[name];
	};

	const getKeysOfForm = (): string[] => {
		return Object.keys(form);
	};

	const validate = (): void => {
		getKeysOfForm().forEach((key: string) => {
			const isValidate = form[key].validate();

			if (!isValidate) errors.push({ name: key, error: true });
		});
	};

	return { addInput, validate, errors };
};
